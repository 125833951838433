<template>
  <div class="solution-home">
    <div class="condition-box">
      <el-card class="box-card">
        <div class="input-form-item">
          <div style="display: flex">
            <div class="input-form-item">
              <div class="input-form-item-label">日期：</div>
              <el-date-picker
                v-model="surgeryDate"
                type="daterange"
                align="center"
                size="mini"
                style="margin-right: 10px"
                unlink-panels
                ref="datepicker"
                @change="handleNextDate"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </div>
          </div>
          <div>
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              icon="el-icon-tickets"
              @click="handleImport"
            >
              手工导入医嘱
            </el-button>
            <el-button
              style="margin-left: 20px"
              size="mini"
              type="primary"
              class="commonBtn"
              icon="el-icon-tickets"
              @click="asyncInfo"
            >
              同步
            </el-button>
          </div>
        </div>
      </el-card>
    </div>
    <div class="table-content">
      <el-card style="width: 30%; height: 100%" class="box-card">
        <el-table
          class="big-table"
          :data="tableData"
          ref="leftTable"
          @row-click="rowClick"
          border
          stripe
          height="65vh"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column prop="doctor" align="center" label="医生"></el-table-column>
          <el-table-column prop="prescriptionNum" align="center" label="处方号"></el-table-column>
          <el-table-column
            prop="openTime"
            align="center"
            label="时间"
            width="150"
          ></el-table-column>
        </el-table>
        <div class="page-box" style="margin-top: 15px">
          <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
          ></el-pagination>
        </div>
      </el-card>
      <!-- right -->
      <el-card style="width: 69%" class="box-card">
        <el-table height="70vh" style="width: 100%" :data="itemList" border>
          <el-table-column prop="name" align="center" label="药品名称"></el-table-column>
          <el-table-column
            prop="frequencyMemo"
            width="150"
            align="center"
            label="频次"
          ></el-table-column>
          <el-table-column prop="dosage" align="center" label="用量" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.dosage && scope.row.dosageUnit">
                {{ scope.row.dosage + scope.row.dosageUnit }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="type"
            align="center"
            label="用药方式"
            width="150"
          ></el-table-column>
        </el-table>
      </el-card>
    </div>
    <el-dialog width="30%" title="导入医嘱" class="add-pacs" :visible.sync="templateInnerVisible">
      <div class="">
        <el-button type="text" size="default" @click="templateDownload">模板下载</el-button>
        <div class="" style="height: 52px; line-height: 52px; display: flex">
          <span>文件上传：</span>
          <el-upload ref="upload" action="" :show-file-list="false" :http-request="fileImport">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getFormatDate } from '../../../../../utils'
export default {
  name: 'Medication',
  components: {},
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isReadonly: true,
      loading: false,
      templateInnerVisible: false,
      tableData: [],
      selectedRow: null,
      spanArr: [],
      pos: 0,
      arr: [],
      spanMethodOption: {},
      currentPage: 1, // 当前页码
      total: undefined, // 总条数
      pageSize: 50, // 每页的数据条数
      surgeryDate: [],
      CISReportInfo: {
        studyEndDate: null,
        studyStartDate: null
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      itemList: [],
      uuid: null
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.patientIndex) {
          let date = new Date()
          let start =
            val?.solutionTime ||
            this.dayjs(date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)).format('YYYY-MM-DD')
          let end = val?.solutionFinishTime || this.getFormatDate(new Date())

          this.surgeryDate = [start, end]
          this.CISReportInfo.studyStartDate = start
          this.CISReportInfo.studyEndDate = end
          this.getAllData()
        }
      },
      immediate: true
    }
  },
  created() {
    this.uuid = this.$route.query.uuid
  },
  methods: {
    handleImport() {
      this.templateInnerVisible = true
    },
    templateDownload() {
      this.axios
        .get(`/v1/webconsole/downloadcenter/template/medicalAdvice.xls`, {
          responseType: 'blob'
        })
        .then((res) => {
          if (res.status === 200) {
            const { data } = res
            const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
            const contentDisposition = res.headers['content-disposition']
            let filename = '医嘱.xls'
            if (contentDisposition) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              const matches = filenameRegex.exec(contentDisposition)
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
              }
            }
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(filename)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
          } else {
            this.$message({
              message: '导出失败',
              type: 'warning'
            })
          }
        })
    },
    fileImport(file) {
      let formData = new FormData()
      formData.append('file', file.file)
      this.$api.post(`/v1/webconsole/doctoradvice/import/excel`, formData).then(() => {
        this.getAllData()
        this.templateInnerVisible = false
      })
    },
    getFormatDate,
    tableRowClassName({ row, rowIndex }) {
      var arr = []
      Object.keys(this.spanMethodOption).forEach((item, index) => {
        if (row.disposeId == item) {
          if (index % 2 === 0) {
            arr.push('warning-row')
          } else {
            arr.push('success-row')
          }
        }
      })
      return arr
    },
    getAllData() {
      if (!this.info.patientIndex) return
      const params = new FormData()
      params.append('startDate', this.CISReportInfo.studyStartDate)
      params.append('stopDate', this.CISReportInfo.studyEndDate)
      params.append('current', this.currentPage)
      params.append('size', this.pageSize)
      this.$api
        .post(`/v1/webconsole/doctoradvice/list/advice/patient/${this.info.patientIndex}`, params)
        .then(({ data }) => {
          if (data.status === 200) {
            this.tableData = data.data.records
            this.itemList = []
            let item = this.tableData[0]
            if (this.uuid) {
              item = this.tableData.find((item) => item.uuid == this.uuid) || this.tableData[0]
            }
            this.rowClick(item)
            this.$refs.leftTable.setCurrentRow(item)
          }
        })
    },
    getSpanArr(data) {
      this.spanArr = [] // tip: 后台获取完成数据后，一定要重置spanArr,避免出现合并混乱！！！！！
      for (let i = 0; i < data.length; i++) {
        // 当为第一行时
        if (i === 0) {
          this.spanArr.push(1) // 1：独自占一行
          this.pos = 0 // 合并的起始行
        } else {
          // 判断当前值是否与上一行的值相等，相等则进行合并
          // --------------------1111----------------------------------
          if (data[i].disposeId && data[i].disposeId === data[i - 1].disposeId) {
            this.spanArr[this.pos] += 1 // 合并单元格：合并的行数 +1
            this.spanArr.push(0) // 0代表单元格是不需要显示, 已经被合并的单元格
          } else {
            this.spanArr.push(1) // 1代表当前这行的数据需要被显示

            this.pos = i // 存放需要合并行的索引
          }
        }
      }
    },
    // 👇合并行和列 示例：第1列的“行”合并 & 第3列和第4列如果金额相等就“列”合并
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // 合并行方法：用于设置要合并的列(这里是指第一列合并)
      if (
        columnIndex === 0 ||
        columnIndex === 3 ||
        columnIndex === 4 ||
        columnIndex === 5 ||
        columnIndex === 6 ||
        columnIndex === 7
      ) {
        const _row = this.spanArr[rowIndex] // 合并行数
        const _col = this.spanArr[rowIndex] > 0 ? 1 : 0 // 0：不显示
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getAllData()
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getAllData()
    },
    handleNextDate() {
      if (this.surgeryDate?.length > 0) {
        this.CISReportInfo.studyStartDate = this.surgeryDate[0]
        this.CISReportInfo.studyEndDate = this.surgeryDate[1]
      } else {
        this.CISReportInfo.studyStartDate = null
        this.CISReportInfo.studyEndDate = null
      }

      this.getAllData()
    },
    rowClick(row) {
      if (!row?.uuid) return this.$message.warning('当前医嘱信息不存在')
      this.$api.post(`/v1/webconsole/doctoradvice/list/detail/${row.uuid}`).then((res) => {
        if (res.data.status === 200) {
          this.itemList = res.data.data
        }
      })
    },
    // 同步
    asyncInfo() {
      if (!this.info.patientIndex) return this.$message.warning('当前患者信息不存在')
      const pdfLoading = this.$loading({
        lock: true,
        text: '同步中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      const params = new FormData()
      params.append('startDate', this.CISReportInfo.studyStartDate)
      params.append('stopDate', this.CISReportInfo.studyEndDate)
      this.$api
        .post(`/v1/webconsole/doctoradvice/sync/patient/${this.info.patientIndex}`, params)
        .then(({ data }) => {
          if (data.status === 200) {
            this.$message.success('同步成功')
            this.getAllData()
          }
        })
        .catch((err) => {
          this.$message.error('同步失败')
        })
        .finally(() => {
          pdfLoading.close()
        })
    }
  }
}
</script>
<style scoped lang="less">
.add-pacs {
  /deep/.el-dialog {
    // width: 1000px !important;
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }
  }
}
.solution-home {
  padding: 0 10px;

  .condition-box {
    padding: 10px 0 20px 0;

    .input-form-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .table-content {
    height: 75vh;
    display: flex;
    justify-content: space-between;
  }
  .big-table {
    /deep/tbody tr:hover > td {
      cursor: pointer;
    }
  }

  .leftTitleContent {
    .leftItemTitle {
      font-weight: bold;
      float: left;
    }
    .leftEleTitle {
      padding-left: 12px;
      cursor: pointer;
      float: right;
      .leftEleTitleItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .foldLineImg {
        width: 18px;
        height: 18px;
        padding-right: 10px;
      }
    }
  }

  .chartInfoOpen {
    width: 100%;
    .lineChart {
      width: 100%;
      height: 320px;
    }
  }

  .page-box {
    height: 3vh;
  }
}
</style>
